<template>
    <div>
        <input :id="'optin' + optin.optinid" type="radio" value="7602" class="hidden" v-model="local" :checked="local === 7602"/>
        <label :for="'optin' + optin.optinid" class="flex items-start cursor-pointer text-lg sm:text-xl text-gray-600">
            <span class="w-6 min-w-6 h-6 inline-block mr-2 mt-1 rounded-full border border-grey flex-no-shrink"></span>
            {{ optin.yescaption }}
        </label>
        <input :id="'optout' + optin.optinid" type="radio" value="7603" class="hidden" v-model="local" :checked="local === 7603"/>
        <label :for="'optout' + optin.optinid" class="flex items-start cursor-pointer text-lg sm:text-xl text-gray-600 pt-3">
            <span class="w-6 min-w-6 h-6 inline-block mr-2 mt-1 rounded-full border border-grey flex-no-shrink"></span>
            {{ optin.nocaption }}
        </label>    
    </div>
</template>

<script>
export default {
    props: {
        optin: {
            type: Object,
            required: true,
        },
        value: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            local: this.value,
        }
    },
    watch: {
        local: function() {
            this.$emit('input', parseInt(this.local));
        },
    },    
}
</script>
<style lang="scss" scoped>
// Radio buttons

input[type="radio"] + label span {
    transition: background .2s,
      transform .2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
} 

input[type="radio"]:checked + label span {
  @apply bg-primary;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label{
   @apply text-primary;
}
</style>