<template>
    <div class="flex flex-col">
        <label :for="'optout' + optin.optinid" class="mt-3 inline-flex items-center cursor-pointer">                                            
            <span class="tm-checkbox relative transition-transform duration-300 ease-in-out">
                <span class="block w-14 h-8 border border-grey rounded-full shadow-inner"></span>
                <span 
                    v-bind:class="{ checked: checked, unchecked: !checked }" 
                    class="absolute block w-6 h-6 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                    <input :id="'optout' + optin.optinid" type="checkbox" class="absolute opacity-0 w-0 h-0" v-model="checked"/>
                </span>
            </span>
            <label :for="'optout' + optin.optinid" class="ml-3 text-lg sm:text-lg">
                {{ optin.caption }}
            </label>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        optin: {
            type: Object,
            required: true,
        },
        value: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            checked: (this.value === 7603),
            local: this.value,
        }
    },    
    watch: {
        checked: function() {
            this.local = (this.checked) ? 7603 : 7602;
            this.$emit('input', parseInt(this.local));
        },
    },
}
</script>
<style lang="scss" scoped>
.unchecked {
    @apply bg-inactive;
}

.checked {
    @apply bg-primary transform translate-x-full;
}
</style>