<template>
    <div class="container w-full lg:w-5/6 xl:w-3/4 2xl:w-1/2 mx-auto flex flex-col py-6 px-3">
        <h2 class="text-3xl text-primary md:text-4xl font-medium mb-2">
            Mijn voorkeuren
        </h2>
        <div v-if="showForm" class="font-base text-black">
            Controleer en wijzig eventueel onderstaande voorkeuren. Klik vervolgens op OPSLAAN.
        </div>
        <div v-if="showForm" class="relative flex flex-wrap">
            <div class="w-full relative">
                <div class="md:mt-6">
                    <form class="mt-8" @submit.prevent="updateOptins(local);">
                        <div class="mx-auto">
                            <div
                                v-for="(optin, index) of optins.contactoptins" 
                                v-bind:key="index">
                                <div class="mx-3 md:flex mb-6">                              
                                    <div class="py-1 px-3">
                                        <h2 class="px-1 text-lg font-bold">
                                            {{ optin.name }}
                                        </h2>
                                        <div v-if="hasDescription(optin)" class="mb-2">
                                            <i>
                                                {{ optin.description }}
                                            </i>
                                        </div>                                        
                                        <div v-if="optin.typeid === 40001">
                                            <HardOptinField :optin="optin" v-model="local[optin.optinid].status"/>
                                        </div>
                                        <div v-if="optin.typeid === 40002">
                                            <SoftOptinField :optin="optin" v-model="local[optin.optinid].status"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mx-3 flex mb-6">
                                <button type="submit"
                                    :disabled="!dirty"
                                    class="mt-3 text-lg font-semibold bg-gray-800 text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black disabled:bg-inactive">
                                    Opslaan
                                </button>
                                <button
                                    @click="$router.push('info')"
                                    class="mt-3 ml-3 text-lg font-semibold bg-gray-200 text-gray-800 rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black">
                                    Terug
                                </button>
                            </div>                           
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="isLoading">
            <font-awesome-icon icon="spinner" spin="spin" size="3x" class="text-primary block m-auto"/>
        </div>
        <div v-if="error">
            <p>
                Er ging iets mis.
            </p>
            <p>
                Probeer het opnieuw of neem contact met ons op via <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </div>
        <div v-if="config.optins.active === false">
            <p>
                Het aanpassen van voorkeuren is niet geactiveerd.
            </p>
        </div>          
        <Modal ref="confirmationModal" title="Gegevens gewijzigd" icon="check-circle">
            Je persoonlijke voorkeuren zijn succesvol opgeslagen.
        </Modal>
        <Modal ref="errorModal" title="Niet gelukt" icon="exclamation-circle">
            <p class="text-lg">
                Er ging iets mis.
            </p>
            <p class="text-lg">
                Probeer het opnieuw of neem contact met ons op via <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </Modal>
    </div>      
</template>

<style lang="scss" scoped>
div.error {
    span {
        @apply text-error;
    }

    input {
        @apply border-error;
    }
}
</style>

<script>
import { mapGetters } from 'vuex'
import Modal from '../components/Modal.vue'
import HardOptinField from '../components/forms/HardOptinField.vue'
import SoftOptinField from '../components/forms/SoftOptinField.vue'
const R = require('ramda')

export default {
    name: 'Opt-ins',
    title: 'Mijn Voorkeuren',
    components: {
        Modal,
        HardOptinField,
        SoftOptinField,
    },
  data() {
    return {
        dirty: false,
        initial: {},
        local: {},
        isLoading: false,
        error: null,
    }
  },
  computed: {
      ...mapGetters(["config", "optins"]),
      showForm: function() {
          return this.isLoading === false && this.error === null && this.optins.contactoptins && this.config.optins.active;
      },
  },
  methods: {
    hasDescription(optin) {
        if (optin.description === '-') {
            return false;
        }
        return !!optin.description;
    },
    updateOptins: async function(input) {
          this.isLoading = true;
          const body = R.pipe(
                R.mapObjIndexed(({status},optinid) => ({
                    optinid: parseInt(optinid),
                    status, 
                })), 
                R.values
            )(input)
          try {
              await this.$store.dispatch("updateOptins", body);
              this.$refs.confirmationModal.toggle();
              this.isLoading = false;
          }
          catch(err) {
              this.$refs.errorModal.toggle();
              this.isLoading = false;
          }
      }
  },
  watch: {
    local: {
        handler() {
            this.dirty = !R.equals(this.initial, this.local);
        },
        deep: true,
    },      
    optins: {
      handler() {
          this.initial = R.pipe(R.map(R.pick(['optinid', 'status','typeid'])), R.reduce((acc, val) => {
                acc[val.optinid] = {
                    status: val.status,
                }              
                return acc
          }, {}))(this.optins.contactoptins);
          this.local = R.pipe(R.map(R.pick(['optinid', 'status','typeid'])), R.reduce((acc, val) => {
                // Convert unknown to opted in for soft opt-ins
                const status = (val.typeid === 40002 && val.status === 7601) ? 7602 : val.status;
                acc[val.optinid] = {
                    status,
                }
                return acc
          }, {}))(this.optins.contactoptins);
      },
      immediate: true,
    },
  },  
  async mounted () {
    this.$mixpanel.track('pageview', {
        distinct_id: this.$auth.user,
        account: this.config.shortname,
        page: "Optins",
    })

    this.isLoading = true;
    try {
        if (this.$store.state.optins.loaded === false && this.config.optins.active) {
            await this.$store.dispatch('setOptins');
        }
    } catch (ex) {
        this.error = true;
    } finally {
        this.isLoading = false;
    }
  },
}
</script>
<style lang="scss" scoped>

</style>